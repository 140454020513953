<template>
  <div class="container">
    <PublicHeader :totalName="$t('public').xq" />
    <div class="title">{{ title }}</div>
    <div class="content" v-html="info"></div>
  </div>
</template>

<script>
import PublicHeader from './components/publicHeaderNew.vue'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      info: '',
      title: ''
    }
  },

  async mounted() {
    if (this.$route.query && this.$route.query.info) {
      this.info = this.$route.query.info
      this.title = this.$route.query.title
    } else {
      const type = this.$route.query.type
      this.title =
        type === 'AboutUs' ? this.$t('home').gywm : this.$t('home').bps
      this.info = await userApi.textInfo({
        isWhite: type !== 'AboutUs'
      })
    }
  }
}
</script>

<style scoped>
.container {
  padding-top: 44px;
  width: 80%;
  margin: 0 auto;
}
.title {
  font-size: 16px;
  margin: 20px 0;
  font-weight: bold;
  text-align: center;
}
.content {
  margin: 10px;
  /* background: #17181e; */
  overflow: hidden;
  padding: 10px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 30px;
  font-weight: normal;
}
</style>

<!-- <template>
  <div>
    <ul>
      <li>
        <img src="../../assets/pc/li1.png" alt="" />
        <div class="bg"></div>
        <div class="box">
          <p class="p1">{{ $t('pcAbout_us').t1 }}</p>
          <p class="p2">{{ $t('pcAbout_us').t2 }}</p>
          <p class="p3">
            {{ $t('pcAbout_us').t3 }}
          </p>
          <p class="p4">
            {{ $t('pcAbout_us').t4 }}
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/pc/li2.png" alt="" />
        <div class="bg"></div>
        <div class="box">
          <p class="p1">{{ $t('pcAbout_us').t5 }}</p>
          <p class="p2">{{ $t('pcAbout_us').t6 }}</p>
          <p class="p3">
            {{ $t('pcAbout_us').t7 }}
          </p>
          <p>
            {{ $t('pcAbout_us').t8 }}
          </p>
          <p class="p4">
            {{ $t('pcAbout_us').t9 }}
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/pc/li3.png" alt="" />
        <div class="bg"></div>
        <div class="box">
          <p class="p1">{{ $t('pcAbout_us').t10 }}</p>
          <p class="p2">{{ $t('pcAbout_us').t11 }}</p>
          <p class="p3">
            {{ $t('pcAbout_us').t12 }}
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/pc/li4.png" alt="" />
        <div class="bg"></div>
        <div class="box">
          <p class="p1">{{ $t('pcAbout_us').t13 }}</p>
          <p class="p2">{{ $t('pcAbout_us').t14 }}</p>
          <p class="p3">
            {{ $t('pcAbout_us').t15 }}
          </p>
          <p class="p4">
            {{ $t('pcAbout_us').t16 }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CurrencyMobileNewIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  li {
    width: 100%;
    height: auto;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 88;
    }
    .box {
      word-break: break-word;
      width: 500px;
      height: auto;
      position: absolute;
      left: 50%;
      top: 20%;
      margin-left: -250px;
      color: #fff;
      z-index: 90;
      text-align: center;

      p {
        width: 100%;
        line-height: 22px;
        font-size: 12px;
      }
      .p1 {
        width: 100%;
        line-height: 30px;
        font-size: 25px;
        margin-bottom: 10px;
      }
      .p2 {
        width: 100%;
        line-height: 30px;
        font-size: 16px;
      }
      .p3 {
        width: 100%;
        line-height: 22px;
        font-size: 12px;
      }
      .p4 {
        width: 100%;
        line-height: 22px;
        font-size: 12px;
      }
    }
  }
}
</style>
 -->
